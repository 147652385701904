import { graphql, PageProps } from 'gatsby';
import { Box } from '@chakra-ui/react';
import SEO from '../components/SEO';
import { SanityPage } from '../../graphql-types';
import Content from '../components/Content';
import useLocale from '../lib/useLocale';
import PageHeader from '../components/PageHeader';

interface CarePageProps extends PageProps {
    data: {
        page: SanityPage;
    };
}

export default function CarePage({ data }: CarePageProps) {
    const { translate } = useLocale();
    return (
        <>
            <SEO
                title={
                    data.page.title ? translate(data.page.title) : 'Cuidados'
                }
            />
            <Box py={20}>
                {data.page.title && <PageHeader title={data.page.title} />}
                <Content data={data.page.content} />
            </Box>
        </>
    );
}

export const query = graphql`
    query CarePageQuery($language: String!) {
        page: sanityPage(slug: { current: { eq: "cuidados" } }) {
            ...SanityPageFragment
        }
        locales: allLocale(filter: { language: { eq: $language } }) {
            ...LocaleFragment
        }
    }
`;
